@import "../utils/animations.scss";

form {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  background-size: cover;
  background-position: center;
}

@media (max-height: 600px) {
  form {
    align-items: unset;
    height: min-content;
  }
}

.form-wrap {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  animation: fade-in 1s forwards;
}

@media (max-width: 640px) {
  .form-wrap {
    align-items: unset;
    overflow-y: scroll;
  }
}

.login {
  background-image: url("../../img/backgrounds/login1.jpg");
}

@media (max-width: 640px) {
  .login {
    background-image: none;
  }
}

.registration {
  background-image: url("../../img/backgrounds/registration1.jpg");
}

@media (max-width: 640px) {
  .registration {
    background-image: none;
  }
}

.form {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 20px;
  background-color: white;
  opacity: 0;
  -webkit-box-shadow: 1px 1px 10px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 10px -1px rgba(0, 0, 0, 0.5);
  animation: fade-in-up 1s forwards 0.5s;
  transition: background-color 200ms ease-in-out, color 400ms linear;
}

@media (max-width: 640px) {
  .form {
    padding: 5px;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    animation: fade-in 1s forwards;
  }
}

.forms-title {
  display: flex;
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 640px) {
  .forms-title {
    font-size: 20px;
    margin-top: 10px;
  }
}

.mask {
  padding: 10px;
}

@media (max-width: 640px) {
  .mask {
    padding: 5px;
  }
}

.input-form-wrap {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-form {
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  margin: 4px;
  border-radius: 8px;
  -webkit-box-shadow: 1px 1px 3x -1px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.5);
}

.input-form:focus-within {
  border: 1px solid darkgray;
}

.input-form-hint-wrap {
  display: none;
  position: absolute;
  height: 0;
  z-index: 1;
}

.input-form-hint {
  display: flex;
  width: 240px;
  height: 85px;
  margin-top: 28px;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  padding: 6px;
  border-radius: 8px;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.95);
}

.input-form-wrap:focus-within > .input-form-hint-wrap {
  display: flex;
  opacity: 0;
  animation: fade-in 0.5s forwards 1s;
}

.input-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border-right: 1px solid lightgray;
  height: 40px;
  width: 40px;
}

.input-icon > .material-icons {
  color: darkgray;
}

.input-form:focus-within > .input-icon {
  border-right: 1px solid darkgray;
}

.input {
  display: flex;
  align-items: center;
  border: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  width: 180px;
  height: 40px;
  color: gray;
  background-color: transparent;
}

.input:focus {
  outline: none;
  color: black;
}

button {
  font: inherit;
  height: 100%;
  width: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.btn-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .btn-wrap {
    margin-bottom: 20px;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 8px;
  width: 240px;
  height: 40px;
  margin-top: 20px;
  -webkit-box-shadow: 1px 1px 3x 0px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
  transition: background-color 200ms ease-in-out, color 400ms linear;
  cursor: pointer;
}

@media (max-width: 640px) {
  .btn {
    margin-top: 10px;
  }
}

.btn:hover {
  background-color: white;
  color: black;
}

.btn-lower-margin {
  margin-top: 10px;
}

.message-wrap {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fade-in 0.5s forwards 0.5s;
}

.message-form {
  display: flex;
  align-items: center;
  margin: 4px;
  border-radius: 8px;
  -webkit-box-shadow: 1px 1px 3x -1px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.5);
  background-color: rgb(216, 86, 86);
}

.message-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 40px;
  width: 40px;
}

.message-icon .material-icons {
  color: white;
}

.message-text {
  display: flex;
  align-items: center;
  border: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  width: 180px;
  height: 40px;
  color: white;
  background-color: transparent;
  font-size: 12px;
}

.response {
  animation: fade-in 0.5s forwards;
}

.loading {
  animation: spin reverse 2s linear infinite;
}

.confirmation-message-wrap {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.confirmation-message {
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin-left: 150px;
  margin-right: 150px;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 20px;
  background-color: white;
  -webkit-box-shadow: 1px 1px 10px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 10px -1px rgba(0, 0, 0, 0.5);
  animation: fade-in 1s forwards;
}

@media (max-width: 640px) {
  .confirmation-message {
    height: 100%;
    width: 100%;
    padding: 20px;
    margin: 0;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.confirmation-message-title {
  display: flex;
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 640px) {
  .confirmation-message-title {
    font-size: 25px;
  }
}

.confirmation-message-text {
  display: flex;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 640px) {
  .confirmation-message-text {
    font-size: 15px;
  }
}
