.function-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

@media (max-width: 1200px) {
  .function-panel {
    flex-wrap: unset;
  }
}

.function-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 33.33%;
}

@media (max-width: 1200px) {
  .function-row {
    justify-content: center;
    height: 100%;
    width: 33.33%;
  }
}

.function-row > .function {
  border: hidden;
}

.exfunction-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 16.66%;
}

@media (max-width: 1200px) {
  .exfunction-row {
    justify-content: center;
    height: 100%;
    width: 16.66%;
  }
}

.function-panel a {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.function {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  user-select: None;
}

.function:hover {
  background: lightgray;
  transition: background-color 200ms ease-in-out, color 400ms linear;
}

.function-row .material-icons {
  font-size: 50px;
  color: black;
}

@media (max-width: 1200px) {
  .function-row .material-icons {
    font-size: 40px;
  }
}

@media (max-width: 640px) {
  .function-row .material-icons {
    font-size: 25px;
  }
}

.exfunction-row .material-icons {
  font-size: 30px;
  color: black;
}

@media (max-width: 1200px) {
  .exfunction-row .material-icons {
    font-size: 30px;
  }
}

@media (max-width: 640px) {
  .exfunction-row .material-icons {
    font-size: 25px;
  }
}
