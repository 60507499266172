@import "../../utils/animations.scss";
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");

.slider-wrap {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  opacity: 0;
  animation: fade-in 1s forwards;
  transition: background-color 200ms ease-in-out, color 400ms linear;
}

.slide1 {
  background-color: white;
}

.slide1 img {
  height: 50%;
}

.slide2 {
  font-family: "Permanent Marker", cursive;
  font-size: 100px;
  color: white;
  -webkit-text-shadow: 3px 1px 4px rgba(0, 0, 0, 0.6);
  text-shadow: 3px 1px 4px rgba(0, 0, 0, 0.6);
  /* background-image: url("../../img/slides/slide2.jpg"); */
  background-image: linear-gradient(to right, #fdc588, #be5efe);
}

@media (max-width: 1200px) {
  .slide2 {
    font-size: 80px;
  }
}

@media (max-width: 640px) {
  .slide2 {
    font-size: 40px;
  }
}

.slide3 {
  background-image: url("../../../img/slides/slide3.jpg");
}

.slide4 {
  /* background-image: url("../../img/slides/slide4.jpg"); */
  font-family: "Permanent Marker", cursive;
  font-size: 100px;
  color: white;
  -webkit-text-shadow: 3px 1px 4px rgba(0, 0, 0, 0.6);
  text-shadow: 3px 1px 4px rgba(0, 0, 0, 0.6);
  /* background-image: url("../../img/slides/slide2.jpg"); */
  background-image: linear-gradient(to right, #be5efe, #fdc588);
}

@media (max-width: 1200px) {
  .slide4 {
    font-size: 80px;
  }
}

@media (max-width: 640px) {
  .slide4 {
    font-size: 40px;
  }
}

.slider-design {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.slider-text {
  display: flex;
}
