.page {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  height: 100vh;
  background: white;
  color: black;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.card {
  display: flex;
  flex-wrap: wrap;
  height: 80vh;
  width: 90%;
  margin-top: 15px;
  margin-bottom: 20px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: white;
  -webkit-box-shadow: 4px 4px 15px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 4px 4px 15px -1px rgba(0, 0, 0, 0.5);
  z-index: 2;
}

@media (max-width: 1200px) {
  .card {
    height: 70vh;
  }
}

@media (max-width: 640px) {
  .card {
    height: 70vh;
  }
}

.page:nth-child(odd) > .card {
  background: black;
  color: white;
}
