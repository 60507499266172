@import "../../utils/animations.scss";

.slider {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.slider-menu {
  display: flex; /**/
  width: 100%;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 1;
  -webkit-transform: translate(0px, 200px);
  transform: translate(0px, 200px);
  animation: move-up-200px 1s forwards 1s;
}

@media (max-width: 640px) {
  .slider-menu {
    display: none;
  }
}

.slider-nav-group {
  display: flex;
  width: 100%;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  bottom: 0;
}

.slider-nav {
  display: flex;
  height: 80px;
  width: 10%;
  justify-content: center;
  align-items: center;
  background-color: rgba(124, 58, 167, 0.75);
  -webkit-box-shadow: 4px 4px 15px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 4px 4px 15px -1px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: 2;
  transition: background-color 200ms ease-in-out, color 400ms linear;
}

@media (max-width: 640px) {
  .slider-nav {
    width: 20%;
  }
}

.slider-nav:hover {
  background-color: white;
}

.slider-nav > .material-icons {
  font-size: 40px;
  color: white;
}

.slider-nav:hover > .material-icons {
  color: black;
}

.slider-nav-left {
  margin-right: 40%;
  -webkit-transform: translate(-200px, 0px);
  transform: translate(-200px, 0px);
  animation: move-left-200px 1s forwards 0.5s;
}

@media (max-width: 640px) {
  .slider-nav-left {
    margin-right: 30%;
  }
}

.slider-nav-right {
  margin-left: 40%;
  -webkit-transform: translate(200px, 0px);
  transform: translate(200px, 0px);
  animation: move-right-200px 1s forwards 0.5s;
}

@media (max-width: 640px) {
  .slider-nav-right {
    margin-left: 30%;
  }
}

.slider-entry {
  display: flex;
  width: 10%;
  justify-content: center;
  cursor: pointer;
}

.slider-rectangle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(197, 117, 95, 0.75);
  height: 80px;
  width: 100%;
  -webkit-box-shadow: 4px 4px 15px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 4px 4px 15px -1px rgba(0, 0, 0, 0.5);
  transition: background-color 200ms ease-in-out, color 400ms linear;
}

.slider-entry:hover > .slider-rectangle {
  background-color: white;
}

.slider-entry-text {
  display: flex;
  color: white;
}

.slider-entry:hover > .slider-rectangle > .slider-entry-text {
  color: black;
}

.slider-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
