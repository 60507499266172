.profile-friend-wrap {
  display: flex;
  position: relative;
  height: 100px;
  width: 100px;
  margin: 5px;
}

@media (max-width: 1200px) {
  .profile-friend-wrap {
    height: 80px;
    width: 80px;
  }
}

.profile-friend {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);

  img {
    height: inherit;
    width: inherit;
    object-fit: cover;
    border-radius: inherit;
  }
}

.friend-preview {
  display: flex;
  position: absolute;
  right: 50%;
  height: 100%;
  max-width: 0px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  transition: max-width 1s;
  z-index: -1;
  overflow: hidden;
}

@media (max-width: 640px) {
  .friend-preview {
    display: none;
  }
}

.profile-friend-wrap:hover .friend-preview {
  max-width: 800px;
  transition: max-width 1s;
}

.friend-preview-content {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.friend-preview-details {
  display: flex;
  flex-direction: column;
  padding: 10px 100px 10px 50px;
}

.friend-preview-name {
  display: flex;
  white-space: nowrap;
  font-size: 25px;
}

.friend-preview-location {
  display: flex;
  flex-direction: row;
}

.friend-preview-location-icon > .material-icons {
  display: flex;
  font-size: 20px;
}

.friend-preview-location-text {
  display: flex;
  white-space: nowrap;
  font-size: 15px;
}
