.subpage {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

.subpage-content {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin: 100px;
}

@media (max-width: 640px) {
  .subpage-content {
    margin: 25px;
  }
}

.title {
  display: block;
  width: 100%;
  font-size: 80px;
  margin-bottom: 50px;
}

@media (max-width: 1200px) {
  .title {
    font-size: 50px;
  }
}

@media (max-width: 640px) {
  .title {
    font-size: 30px;
    margin-bottom: 30px;
  }
}

.description {
  width: 100%;
  display: block;
  font-size: 22px;
}

@media (max-width: 1200px) {
  .description {
    font-size: 20px;
  }
}

@media (max-width: 640px) {
  .description {
    font-size: 18px;
  }
}
