@import "../utils/animations.scss";

.dashboard-landing {
  display: flex;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
