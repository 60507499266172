.content {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  border: 2px solid var(--gs0);
  height: 100vh;
  width: 100%;
  position: fixed;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
