.header-wrap {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.header {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-right: 30px;
  height: 80px;
  z-index: 3;
}

@media (max-width: 640px) {
  .header {
    right: unset;
    height: 70px;
    margin: 10px;
    width: 95%;
  }
}

.header-space {
  width: 100%;
  min-height: 110px;
}

@media (max-width: 1200px) {
  .header-space {
    min-height: 110px;
  }
}

@media (max-width: 640px) {
  .header-space {
    min-height: 90px;
  }
}

.footer {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  bottom: 1%;
  width: 98%;
  z-index: 1;
}

@media (max-width: 1200px) {
  .footer {
    margin: 0;
    bottom: 8%;
  }
}

@media (max-width: 400px) {
  .footer {
    bottom: 4%;
  }
}

.headpage-footer-space {
  width: 100%;
  min-height: 100px; /* var(--headpage-footer-space); */
  animation: var(--hp-fs-animation);
}

@media (max-width: 1200px) {
  .headpage-footer-space {
    min-height: 70px; /* var(--headpage-footer-space-tablet); */
    animation: var(--hp-fs-animation-tablet);
  }
}

@media (max-width: 640px) {
  .headpage-footer-space {
    min-height: 50px; /* var(--headpage-footer-space-mobile); */
    animation: var(--hp-fs-animation-mobile);
  }
}

.dashboard-footer-space {
  min-height: 20px; /* var(--dashboard-footer-space); */
  animation: var(--db-fs-animation);
}

@media (max-width: 1200px) {
  .dashboard-footer-space {
    min-height: 20px; /* var(--dashboard-footer-space-tablet); */
    animation: var(--db-fs-animation-tablet);
  }
}

@media (max-width: 640px) {
  .dashboard-footer-space {
    min-height: 0px; /* var(--dashboard-footer-space-mobile); */
    animation: var(--db-fs-animation-mobile);
  }
}

.menu-wrap {
  display: flex;
  height: 100%;
  border-radius: 200px;
  background-color: white;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, 0.5);
  transition: background-color 200ms ease-in-out, color 400ms linear;
}

@media (max-width: 640px) {
  .menu-wrap {
    width: 100%;
    border-radius: 40px;
  }
}

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0px;
  margin-right: 25px;
}

@media (max-width: 640px) {
  .menu {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 15px;
  background-color: transparent;
  color: black;
  transition: background-color 200ms ease-in-out, color 400ms linear;
  border-bottom: 2px black solid;
  cursor: pointer;
  user-select: None;
}

@media (max-width: 640px) {
  .menu-item {
    font-size: 15px;
  }
}

.menu-item:hover {
  border-color: black;
  background-color: black;
  color: white;
  border-bottom: 2px black solid;
}

.footer-menu-item {
  background-color: transparent;
  color: black;
  transition: background-color 200ms ease-in-out, color 400ms linear;
  margin: 4px;
  padding: 15px;
}

.footer-menu-item:hover {
  color: white;
}

@media (max-width: 1200px) {
  .footer-menu-item {
    margin: 3px;
    padding: 15px;
  }
}

@media (max-width: 640px) {
  .footer-menu-item {
    padding: 8px;
  }
}
