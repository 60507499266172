@import "../utils/animations.scss";
@import "../utils/colors.scss";

textarea {
  border-radius: 10px;
  padding: 10px;
  font-family: inherit;
  outline: none;
  resize: none;
}

.profile-settings {
  display: flex;
  position: relative;
  flex-direction: row;
  // padding: 50px;
  width: 100%;
}

@media (max-width: 640px) {
  .profile-settings {
    padding: 0;
    justify-content: unset;
    flex-direction: column;
    overflow-y: scroll;
  }

  .profile-settings::-webkit-scrollbar {
    width: 0px;
  }
}

.back-icon {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  height: 30px;
  width: 30px;
  border: 3px solid black;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.back-icon:hover {
  border: 3px solid rgba(0, 0, 0, 0.5);
}

.back-icon:hover > .material-icons {
  color: rgba(0, 0, 0, 0.5);
}

.back-icon > .material-icons {
  font-size: 25px;
}

.profile-settings-menu-wrap {
  display: flex;
  width: 20%;
  background-color: #f8f9fb;
  -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1200px) {
  .profile-settings-menu-wrap {
    width: 35%;
  }
}

@media (max-width: 640px) {
  .profile-settings-menu-wrap {
    width: 100%;
  }
}

.profile-settings-menu {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.profile-settings-menu-entry-wrap {
  display: flex;
}

.profile-settings-menu-entry {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 5px;
  align-items: center;
  cursor: pointer;
}

.profile-settings-menu-entry-icon {
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}

.profile-settings-menu-entry-icon > .material-icons {
  font-size: 25px;
  transition: color 200ms ease-in-out;
}

.profile-settings-menu-entry-title {
  display: flex;
  font-size: 18px;
  transition: color 200ms ease-in-out;
}

.profile-settings-details-wrap {
  display: flex;
  width: 100%;
}

@media (max-width: 640px) {
  .profile-settings-details-wrap {
    width: 100%;
  }
}

.profile-settings-details {
  display: flex;
  width: 100%;
  padding: 30px;
  padding-left: 90px;
  padding-right: 90px;
  overflow-y: scroll;
}

@media (max-width: 1200px) {
  .profile-settings-details {
    padding: 30px;
  }
}

@media (max-width: 640px) {
  .profile-settings-details {
    padding: 20px;
    justify-content: center;
  }
}

.profile-settings-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  animation: fade-in 1s forwards;
}

.profile-settings-page-title {
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  font-size: 25px;
}

.profile-settings-page-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: start;
}

.profile-setting-label {
  display: flex;
  padding: 10px;
}
