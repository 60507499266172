@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

.btn-google-wrap {
  display: flex;
  justify-content: center;
}

.btn-google {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #000000;
  background-color: white;
  padding: 4px;
  margin: 10px;
  -webkit-box-shadow: 1px 1px 3x -1px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.5);
  transition: background-color 200ms ease-in-out, color 400ms linear;
  cursor: pointer;
}

@media (max-width: 640px) {
  .btn-google {
    margin: 0px;
  }
}

.btn-google:hover {
  background-color: lightgray;
}

.btn-google-icon {
  height: 52px;
  width: 52px;
  content: url("../../icons/btn_google_light_normal_ios.svg");
}

@media (max-width: 640px) {
  .btn-google-icon {
    height: 32px;
    width: 32px;
  }
}

.btn-google-text {
  margin-left: 15px;
  margin-right: 15px;
}
