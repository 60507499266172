@import "../utils/animations.scss";

.dashboard-page::before {
  animation: var(--db-animation);
}

.dashboard-card-wrap {
  height: 100%; /* var(--dashboard-cardwrap-height); */
  width: 100%; /* var(--dashboard-cardwrap-width); */
  animation: var(--dbcw-animation);
}

@media (max-width: 1200px) {
  .dashboard-card-wrap {
    width: 100%; /* var(--dashboard-cardwrap-width-tablet); */
    animation: var(--dbcw-animation-tablet);
  }
}

@media (max-width: 640px) {
  .dashboard-card-wrap {
    width: 100%; /* var(--dashboard-cardwrap-width-mobile); */
    animation: var(--dbcw-animation-mobile);
  }
}

.dashboard-card1 {
  height: 100%; /* var(--dashboard-card1-height); */
  width: 5%; /* var(--dashboard-card1-width); */
  animation: var(--dbc1-animation);
}

@media (max-width: 1200px) {
  .dashboard-card1 {
    height: 10%; /* var(--dashboard-card1-height-tablet); */
    width: 95%; /* var(--dashboard-card1-width-tablet); */
    animation: var(--dbc1-animation-tablet);
  }
}

@media (max-width: 640px) {
  .dashboard-card1 {
    height: 10%; /* var(--dashboard-card1-height-mobile); */
    width: 95%; /* var(--dashboard-card1-width-mobile); */
    animation: var(--dbc1-animation-mobile);
  }
}

.dashboard-card2 {
  height: 100%; /* var(--dashboard-card2-height); */
  width: 93%; /* var(--dashboard-card2-width); */
  animation: var(--dbc2-animation);
}

@media (max-width: 1200px) {
  .dashboard-card2 {
    height: 90%; /* var(--dashboard-card2-height-tablet); */
    width: 95%; /* var(--dashboard-card2-width-tablet); */
    animation: var(--dbc2-animation-tablet);
  }
}

@media (max-width: 640px) {
  .dashboard-card2 {
    height: 80%; /* var(--dashboard-card2-height-mobile); */
    width: 95%; /* var(--dashboard-card2-width-mobile); */
    animation: var(--dbc2-animation-mobile);
  }
}
