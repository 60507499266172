::-webkit-scrollbar-thumb {
  background-color: transparent;
  transition: background-color 1s;
}

.profile-wrap {
  display: flex;
  position: relative;
  padding: 5%;
  width: 100%;
  justify-content: center;
}

@media (max-width: 640px) {
  .profile-wrap {
    padding: 0px;
    overflow-y: scroll;
  }
}

.profile {
  display: flex;
  position: relative;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
}

@media (max-width: 1200px) {
  .profile {
    // padding: 50px;
  }
}

@media (max-width: 640px) {
  .profile {
    flex-direction: column;
    justify-content: unset;
    padding: 0px;
  }
}

.profile-options-wrap {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

@media (min-width: 640px) {
  .mobile {
    display: none;
  }
  .no-mobile {
    display: flex;
  }
}

@media (max-width: 640px) {
  .mobile {
    display: flex;
  }
  .no-mobile {
    display: none;
  }
}

.profile-options {
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.profile-option {
  display: flex;
  padding: 10px;
}

.profile-option-icon {
  display: flex;
  height: 30px;
  width: 30px;
  border: 3px solid black;
  border-radius: 50%;
  background-color: white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profile-option-icon:hover {
  border: 3px solid rgba(0, 0, 0, 0.5);
}

.profile-option-icon > .material-icons {
  font-size: 20px;
}

.profile-option-icon:hover > .material-icons {
  color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 640px) {
  .profile-option-icon {
    background-color: transparent;
    border: 3px solid rgba(255, 255, 255, 0.5);
  }

  .profile-option-icon:hover {
    border: 3px solid rgba(255, 255, 255, 0.9);
  }

  .profile-option-icon > .material-icons {
    color: white;
  }

  .profile-option-icon:hover > .material-icons {
    color: white;
  }
}

.profile-user-card-wrap {
  display: flex;
  height: 100%;
  width: 35%;
  margin: auto;
}

@media (max-width: 640px) {
  .profile-user-card-wrap {
    min-height: 100%;
    width: 100%;
  }
}

.profile-user-card {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 20px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
  transition: background-color 200ms ease-in-out, color 400ms linear;

}

@media (max-width: 640px) {
  .profile-user-card {
    border-radius: 0px;
  }
}

.profile-picture-wrap {
  display: flex;
  position: relative;
  min-height: 50%;
  width: 100%;
}

@media (max-width: 640px) {
  .profile-picture-wrap {
    min-height: 65%;
  }
}

.profile-picture-placeholder {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.profile-picture-placeholder > .material-icons {
  font-size: 100px;
  color: rgba(0, 0, 0, 0.5);
}

.profile-picture {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("../../img/example-user-picture.jpg");
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.profile-function-wrap {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.profile-function {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.profile-function-icon {
  display: flex;
  border: 3px solid rgba(255, 255, 255, 0.5);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-function-icon:hover {
  border: 3px solid rgba(255, 255, 255, 0.9);
}

.profile-function-icon > .material-icons {
  font-size: 33px;
  color: white;
}

.profile-content-wrap {
  padding: 25px;
  text-align: start;
}

.mobile-profile-dashboard-header {
  display: none;
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 30px;
}

@media (max-width: 640px) {
  .mobile-profile-dashboard-header {
    display: flex;
  }
}

.mobile-profile-dashboard-title {
  display: flex;
  font-size: 25px;
}

.mobile-profile-dashboard-title-adds-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mobile-profile-dashboard-title-adds-icon {
  display: flex;
  margin-right: 3px;
}

.mobile-profile-dashboard-title-adds-icon > .material-icons {
  font-size: 16px;
}

.mobile-profile-dashboard-title-adds-text {
  display: flex;
  font-size: 15px;
}

.profile-description-wrap {
  margin-top: 10px;
  margin-bottom: 20px;
}

.profile-description-title {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}

.profile-description-text {
  font-size: 12px;
}

.profile-links-wrap {
  margin-top: 10px;
  margin-bottom: 20px;
}

.profile-links-title {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}

.profile-links-table {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}

.profile-dashboard-wrap {
  height: 100%;
  width: 50%;
  margin: auto;
}

@media (max-width: 640px) {
  .profile-dashboard-wrap {
    width: 100%;
    margin: 0px;
  }
}

.profile-dashboard {
  display: flex;
  height: 100%;
  flex-direction: column;
  text-align: start;
}

@media (max-width: 1200px) {
  .profile-dashboard {
  }
}

@media (max-width: 640px) {
  .profile-dashboard {
  }
}

.profile-dashboard-header {
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  padding: 40px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  background-color: white;
  // -webkit-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.5);
  // box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.5);
  transition: background-color 200ms ease-in-out, color 400ms linear;
}

@media (max-width: 1200px) {
  .profile-dashboard-header {
    padding: 20px;
    padding-bottom: 10px;
  }
}

@media (max-width: 640px) {
  .profile-dashboard-header {
    display: none;
  }
}

.profile-dashboard-title {
  font-size: 30px;
}

@media (max-width: 1200px) {
  .profile-dashboard-title {
    font-size: 25px;
  }
}

.profile-dashboard-title-adds-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-dashboard-title-adds-icon {
  display: flex;
  margin-right: 3px;
}

.profile-dashboard-title-adds-icon > .material-icons {
  font-size: 16px;
}

.profile-dashboard-title-adds-text {
  display: flex;
  font-size: 15px;
}

.profile-dashboard-body-wrap {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.profile-dashboard-body {
  display: flex;
  width: 100%;
  padding: 20px;
  padding-right: 15px;
  overflow-y: scroll;
}

.profile-dashboard-body:hover{
  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
}

.profile-dashboard-events {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-friendlist-placeholder {
  display: flex;
  height: 100%;
  width: 150px;
}

@media (max-width: 1200px) {
  .profile-friendlist-placeholder {
    width: 110px;
  }
}

.profile-friendlist-wrap {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  pointer-events: none;
  overflow-y: scroll;

}

.profile-friendlist-wrap:hover {
  &::-webkit-scrollbar-thumb {
    background: lightgray;
  }
}

@media (max-width: 640px) {
  .profile-friendlist-wrap {
    position: relative;
    width: 100%;
  }
}

.profile-friendlist {
  display: flex;
  position: absolute;
  right: 0;
  flex-direction: column;
  padding: 10px;
  pointer-events: visible;
}

@media (max-width: 640px) {
  .profile-friendlist {
    flex-direction: unset;
    width: 100%;
  }
}