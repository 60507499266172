@import "../utils/animations.scss";

.headpage-content-wrap {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.headpage-interface-wrap {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;
}

.headpage-interface {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.head-page::before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  transform: translateZ(0);
  background: #1b2030 url(../../img/slides/slide4.jpg) 50% 0 no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
  animation: var(--hp-animation);
}

.head-page {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  color: black;
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  background: transparent
    linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 40%, rgb(34, 34, 34) 100%);
}

.head-page::after {
  content: "";
  width: 100%;
  height: 100vh;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.head-card-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  height: 85%; /* var(--headpage-cardwrap-height); */
  width: 100%; /* var(--headpage-cardwrap-width); */
  z-index: 0;
  animation: var(--hpcw-animation);
  pointer-events: none;
}

@media (max-width: 1200px) {
  .head-card-wrap {
    align-content: flex-start;
    height: 100%; /* var(--headpage-cardwrap-height-tablet); */
    width: 80%; /* var(--headpage-cardwrap-width-tablet); */
    animation: var(--hpcw-animation-tablet);
    /* animation: var(--hcw-mobile-animation); */
  }
}

@media (max-width: 640px) {
  .head-card-wrap {
    height: 100%; /* var(--headpage-cardwrap-height-mobile); */
    width: 95%; /* var(--headpage-cardwrap-width-mobile); */
    animation: var(--hpcw-animation-mobile);
  }
}

.head-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: white;
  -webkit-box-shadow: 4px 4px 15px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 4px 4px 15px -1px rgba(0, 0, 0, 0.5);
  transition: background-color 200ms ease-in-out, color 400ms linear;
  pointer-events: visible;
}

.head-card1 {
  display: flex;
  height: 100%; /* var(--headpage-card1-height); */
  width: 10vw; /* var(--headpage-card1-width); */
  overflow: hidden;
  animation: var(--hpc1-animation);
}

@media (max-width: 1200px) {
  .head-card1 {
    height: 10%; /* var(--headpage-card1-height-tablet); */
    margin-bottom: 10px;
    width: 95%; /* var(--headpage-card1-width-tablet); */
    animation: var(--hpc1-animation-tablet);
  }
}

@media (max-width: 640px) {
  .head-card1 {
    min-height: 30px;
    height: 10%; /* var(--headpage-card1-height-mobile); */
    width: 95%; /* var(--headpage-card1-width-mobile); */
    animation: var(--hpc1-animation-mobile);
  }
}

.head-card2 {
  height: 100%; /* var(--headpage-card2-height); */
  width: 65vw; /* var(--headpage-card2-width); */
  overflow: hidden;
  margin-left: 0.5%;
  animation: var(--hpc2-animation);
}

@media (max-width: 1200px) {
  .head-card2 {
    height: 80%; /* var(--headpage-card2-height-tablet); */
    width: 95%; /* var(--headpage-card2-width-tablet); */
    margin-left: 0;
    animation: var(--hpc2-animation-tablet);
  }
}

@media (max-width: 640px) {
  .head-card2 {
    height: 80%; /* var(--headpage-card2-height-mobile); */
    width: 95%; /* var(--headpage-card2-width-mobile); */
    animation: var(--hpc2-animation-mobile);
  }
}

.head-card2-content {
  display: flex;
  height: 100%;
  width: 100%;
  animation: fade-in 1s forwards;
}

.placeholder {
  display: flex;
  padding: 30px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
