.language-switcher {
  display: flex;
  height: 70px;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
}

@media (max-width: 640px) {
  .language-switcher > img {
    height: 30px;
  }

  .mobile-hidden {
    display: none;
  }
}

.language-switcher > svg {
  height: 70%;
}
