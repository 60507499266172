.side-menu-wrap {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  padding: 6px;
  height: 80px;
  z-index: 1;
}

.side-menu {
  display: flex;
  height: 100%;
  width: 100%;
}

@media (max-width: 640px) {
  .side-menu {
    position: fixed;
    top: unset;
    left: 0;
    bottom: 0;
    height: 40px;
    margin: 5px;
  }
}

.side-menu > a {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.side-menu-element {
  display: flex;
  height: 100%;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .side-menu-element {
    margin-right: 0px;
  }
}

.side-menu-image {
  display: flex;
  height: 100%;
  cursor: pointer;
  margin-right: 10px;
}

@media (max-width: 640px) {
  .side-menu-image {
    margin-right: 0px;
  }
}

.side-menu-image > img {
  height: 100%;
}

.side-menu-icon {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.side-menu-icon > .material-icons {
  font-size: 30px;
  border: 3px solid #383838;
  border-radius: 50%;
  color: #383838;
  cursor: pointer;
  transition: background-color 200ms ease-in-out, color 400ms linear;
}

@media (max-width: 640px) {
  .side-menu-icon > .material-icons {
    font-size: 20px;
  }
}

@media (min-width: 640px) {
  .side-lang {
    display: none;
  }
}
