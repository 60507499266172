@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

.App {
  text-align: center;
  font-family: "Montserrat";
  color: black;
}

a {
  text-decoration: none;
  color: unset;
}

::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: lightgray;
}