.profile-event {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
  transition: background-color 200ms ease-in-out, color 400ms linear;
  cursor: pointer;
}

.profile-event-icon-wrap {
  display: flex;
  padding: 10px;
}

.profile-event-icon {
  display: flex;
  flex-direction: column;
  height: 70px;
  width: 50px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px) {
  .profile-event-icon {
    height: 50px;
    width: 30px;
  }
}

.profile-event-icon-img {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.profile-event-icon-img > .material-icons {
  font-size: 50px;
}

@media (max-width: 1200px) {
  .profile-event-icon-img > .material-icons {
    font-size: 35px;
  }
}

.profile-event-icon-img-caption {
  display: flex;
  margin-top: 10px;
  position: absolute;
  z-index: 1;
}

.profile-event-icon-caption-month {
  font-size: 15px;
  text-transform: uppercase;
  border-bottom: 1px solid transparent;
  transition: 1s;
}

.profile-event-content-wrap {
  display: flex;
  padding: 10px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
}

.profile-event-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-event-content-title {
  display: flex;
  font-size: 30px;
}

@media (max-width: 1200px) {
  .profile-event-content-title {
    font-size: 23px;
  }
}

.profile-event-content-title-adds {
  display: flex;
  font-size: 15px;
}

@media (max-width: 1200px) {
  .profile-event-content-title-adds {
    font-size: 12px;
  }
}

// event animation

.profile-event:hover {
  .profile-event-icon-img {
    animation: wiggle 2s infinite;
  }

  .profile-event-icon-caption-month {
    border-color: black;
    transition: 1s;
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(5deg);
  }
  66% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
