/* @import "../utils/animations.scss"; */

@keyframes fade-in-up {
  from {
    -webkit-transform: translate(0px, 30px);
    transform: translate(0px, 30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes move-up-200px {
  from {
    -webkit-transform: translate(0px, 200px);
    transform: translate(0px, 200px);
  }
  to {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@keyframes move-left-200px {
  from {
    -webkit-transform: translate(-200px, 0px);
    transform: translate(-200px, 0px);
  }
  to {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@keyframes move-right-200px {
  from {
    -webkit-transform: translate(200px, 0px);
    transform: translate(200px, 0px);
  }
  to {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

/* Headpage to Dashboard animations */

/* Cardwrap */
@keyframes scale-hp-to-db-cardwrap {
  from {
    height: 85%; /* var(--headpage-cardwrap-height); */
  }
  to {
    height: 100%; /* var(--dashboard-cardwrap-height); */
  }
}

@keyframes scale-hp-to-db-cardwrap-tablet {
  from {
    width: 80%; /* var(--headpage-cardwrap-width-tablet); */
  }
  to {
    width: 100%; /* var(--dashboard-cardwrap-width-tablet); */
  }
}

@keyframes scale-hp-to-db-cardwrap-mobile {
  from {
    width: 95%; /* var(--headpage-cardwrap-width-mobile); */
  }
  to {
    width: 100%; /* var(--dashboard-cardwrap-width-mobile); */
  }
}

/* Card1 */
@keyframes scale-hp-to-db-card1 {
  from {
    width: 10vw; /* var(--headpage-card1-width); */
  }
  to {
    width: 5%; /* var(--dashboard-card1-width); */
  }
}

@keyframes scale-hp-to-db-card1-tablet {
  from {
    height: 10%; /* var(--headpage-card1-height-tablet); */
  }
  to {
    height: 10%; /* var(--dashboard-card1-height-tablet); */
  }
}

@keyframes scale-hp-to-db-card1-mobile {
  from {
    height: 10%; /* var(--headpage-card1-height-mobile); */
  }
  to {
    height: 10%; /* var(--dashboard-card1-height-mobile); */
  }
}

/* Card2 */
@keyframes scale-hp-to-db-card2 {
  from {
    width: 65vw; /* var(--headpage-card2-width); */
  }
  to {
    width: 93%; /* var(--dashboard-card2-width); */
  }
}

@keyframes scale-hp-to-db-card2-tablet {
  from {
    height: 80%; /* var(--headpage-card2-height-tablet); */
  }
  to {
    height: 90%; /* var(--dashboard-card2-height-tablet); */
  }
}

@keyframes scale-hp-to-db-card2-mobile {
  from {
    height: 80%; /* var(--headpage-card2-height-mobile); */
  }
  to {
    height: 80%; /* var(--dashboard-card2-height-mobile); */
  }
}

/* footer space */
@keyframes scale-hp-to-db-fs {
  from {
    min-height: 100px; /* var(--headpage-footer-space); */
  }
  to {
    min-height: 20px; /* var(--dashboard-footer-space); */
  }
}

@keyframes scale-hp-to-db-fs-tablet {
  from {
    min-height: 70px; /* var(--headpage-footer-space-tablet); */
  }
  to {
    min-height: 20px; /* var(--dashboard-footer-space-tablet); */
  }
}

@keyframes scale-hp-to-db-fs-mobile {
  from {
    min-height: 50px; /* var(--headpage-footer-space-mobile); */
  }
  to {
    min-height: 0px; /* var(--dashboard-footer-space-mobile); */
  }
}

/*  Dashboard to Headpage animations */

/* cardwrap */
@keyframes scale-db-to-hp-cardwrap {
  from {
    height: 100%; /* var(--dashboard-cardwrap-height); */
  }
  to {
    height: 85%; /* var(--headpage-cardwrap-height); */
  }
}

@keyframes scale-db-to-hp-cardwrap-tablet {
  from {
    width: 100%; /* var(--dashboard-cardwrap-width-tablet); */
  }
  to {
    width: 80%; /* var(--headpage-cardwrap-width-tablet); */
  }
}

@keyframes scale-db-to-hp-cardwrap-mobile {
  from {
    width: 100%; /* var(--dashboard-cardwrap-width-mobile); */
  }
  to {
    width: 95%; /* var(--headpage-cardwrap-width-mobile); */
  }
}

/* card1 */
@keyframes scale-db-to-hp-card1 {
  from {
    width: 5%; /* var(--dashboard-card1-width); */
  }
  to {
    width: 10vw; /* var(--headpage-card1-width); */
  }
}

@keyframes scale-db-to-hp-card1-tablet {
  from {
    height: 10%; /* var(--dashboard-card1-height-tablet); */
  }
  to {
    height: 10%; /* var(--headpage-card1-height-tablet); */
  }
}

@keyframes scale-db-to-hp-card1-mobile {
  from {
    height: 10%; /* var(--dashboard-card1-height-mobile); */
  }
  to {
    height: 10%; /* var(--headpage-card1-height-mobile); */
  }
}

/* card2 */
@keyframes scale-db-to-hp-card2 {
  from {
    width: 93%; /* var(--dashboard-card2-width); */
  }
  to {
    width: 65vw; /* var(--headpage-card2-width); */
  }
}

@keyframes scale-db-to-hp-card2-tablet {
  from {
    height: 90%; /* var(--dashboard-card2-height-tablet); */
  }
  to {
    height: 80%; /* var(--headpage-card2-height-tablet); */
  }
}

@keyframes scale-db-to-hp-card2-mobile {
  from {
    height: 80%; /* var(--dashboard-card2-height-mobile); */
  }
  to {
    height: 80%; /* var(--headpage-card2-height-mobile); */
  }
}

/* footer space */
@keyframes scale-db-to-hp-fs {
  from {
    min-height: 20px; /* var(--dashboard-footer-space); */
  }
  to {
    min-height: 100px; /* var(--headpage-footer-space); */
  }
}

@keyframes scale-db-to-hp-fs-tablet {
  from {
    min-height: 20px; /* var(--dashboard-footer-space-tablet); */
  }
  to {
    min-height: 70px; /* var(--headpage-footer-space-tablet); */
  }
}

@keyframes scale-db-to-hp-fs-mobile {
  from {
    min-height: 0px; /* var(--dashboard-footer-space-mobile); */
  }
  to {
    min-height: 50px; /* var(--headpage-footer-space-mobile); */
  }
}
